import React, {useEffect, useState, useRef} from 'react'
import {graphql, Link} from 'gatsby'
import styled from 'styled-components'
import {useSpring, animated as a} from 'react-spring'
import {CategoriesMenu, getCategoryUuidParam} from '../components/Categories'
import {useColorModeContext} from '../layouts'
import css from '@styled-system/css'

const ProjectsWrapper = styled.div`

  display: flex;
  flex-wrap: wrap;

  
 .project_item {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 515px;
    margin-right: 5px;
    margin-bottom: 5px;

    .descr {
      z-index: 2;
      position: absolute;
      top: 0px;
      left: 0px;
      
      color: black;
      opacity: 0.9;
      padding: 10px;

      .title {
        font-size: 1.5rem;
        padding: 10px 15px;
        background: white;

      }

    }
    

    .image {
      width: 100%;
      max-height: 400px;
      background-position: 50% 50%;
      object-fit: cover;
      transition: height 0.8s;

    }

    .categories {
      margin-top: 10px;
      padding: 10px 15px;
      width: fit-content;
      background: white;
      
    }

    .circle {
      background: white;
      position: absolute;
      z-index: 1;
      opacity: 0.8;
    }
 }
 ${css({
   flexDirection: ['column', 'row'],
   alignItems: 'center',
   '.image' : {
      width: []
   }
 })} 
`

const ProjectImage = ({src}) => {

  const [size, setSize] = useState(0)
  const ref = useRef()

  useEffect(() => {
    ref.current && setSize(ref.current.offsetWidth)
  }, [ref])
  return <div className="image" ref={ref} style={{backgroundImage: `url(${src})`, height: size}}/>
  

}

const ProjectItem = (p) => {

  const [hovered, setHover] = useState(false)


  const [{width, top, left}, set]= useSpring(() => ({
    from: { width: hovered ? 0 : 1500, left: -1000, top:-1000}
  
  }))

  
  if(hovered) set({width: 1000, left: -300, top: -300})
  else set({width: 0, left: 40, top: 25})

  useEffect(() => {
    

  }, [p.active_category_uuid])

  
  return <div className="project_item" onMouseOver={()=> setHover(true)} onMouseOut={() => setHover(false)}>
    <Link to={`/projects/${p.project_slug}`}>
      
      <div className="descr">
        <h3 className="title">{p.project_name}</h3>  
        <div className="categories">{p.categories.map((c, i) => <span className="category">{c.label}</span>)}</div>
      </div>
      <a.div className="circle" style={{ top: top, left: left, width: width.interpolate(w => `${w}px`), height: width.interpolate(w => `${w}px`)}}/>

      <ProjectImage src={p.image_src} />
      
      </Link>
  </div>
}



export default (props) => {

    const [{mode}, dispatchColorMode] = useColorModeContext()

    mode === 'default' || dispatchColorMode('default')
    
    const location = props.location
    
    const {_categories, _projects} = props.data


    const __categories = _categories.nodes.filter(c => c.group === "project")

    __categories.sort((a, b) => a.order - b.order)

    const active_category_uuid = getCategoryUuidParam(location)

    const __projects = active_category_uuid === ""
    ? _projects.nodes
    : _projects.nodes.filter(p => p.categories_uuid.includes(active_category_uuid))

    __projects.sort((a, b) => a.order - b.order)

    const ___projects = __projects
    .map(({images, categories_uuid, ...rest}) => {
      images.sort((a, b) => a.order - b.order)
      const image_src = images[0] && images[0].uris['_600']
      const categories = __categories.filter(({category_uuid}) => categories_uuid.includes(category_uuid))
      return {image_src, categories, ...rest}})
    

    
    


    return <>
      <CategoriesMenu {...{categories: __categories, location}} />
      <ProjectsWrapper>
        {___projects.map((p, i) => <ProjectItem key={p.project_slug} {...{...p, active_category_uuid}}  />)}
      </ProjectsWrapper>
    </>
}



export const pageQuery = graphql`
    query {
        _projects: allMongodbCmsProjects {
          nodes {
            project_slug
            project_name
            categories_uuid
            order
            images {
              uris {
                _600
              }
              order
            }
          }
        }
        _categories: allMongodbCmsCategories {
          nodes {
            label
            category_uuid
            group
            order
          }
        }
      }
      
`

import React, {useEffect, useRef, useState} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import styled, {keyframes} from 'styled-components'
import {useSpring, animated as a} from 'react-spring'
import {pwindow, isMobile} from '../utils'
import Modal from './Modal'
import css from '@styled-system/css'
import Seo from '../components/Seo'

const hoverline = keyframes`

from {
  width: 0%;
}

to {
  width: 100%;
}

`
const outline = keyframes`

from {
  width: 0%;
}

to {
  width: 100%;
}

`



const CategoriesWrapper = styled.div`


margin-bottom: 33px;

.panel{
  display: flex;
  flex-wrap: wrap;
}

 .sticky {
  position: fixed;
  top: 0px;
  z-index: 3;
  background:white;
  width: 100%;
}
${css({
  marginBottom: ['0px', '33px']
})}
`

const CategoryWrapper = styled.div`
  margin: 10px 0px;
  margin-right: 15px;
  cursor: pointer; 
  width: fit-content;
  font-size: 1.1rem;



  .label{
    display: block;
    padding: 10px 20px;
    
  }
  

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: white;

    display: block;
  }

  &:hover {
    background: #fcfcfc;
    &:after {
      background: #999;
      animation: ${hoverline} 0.4s ease;
      
    } 
  }
  &.active {
    &:after {
      background: #000;
      animation: ${hoverline} 0.8s ease;      
    } 
  }


`


export const getCategoryUuidParam = (location) => {
  const {search} = location

  const category_match = search.match(/category_uuid=([^&]+)/)
  return category_match !== null ? category_match[1] : ''

}

export const Category = ({c, active}) => (<CategoryWrapper
    onClick={()=> navigate('/projects/?category_uuid='+c.category_uuid)} 
    className={`${active ? 'active': ''}`}>
  <span className="label">{c.label}</span>
</CategoryWrapper>)


const MoreWraper = styled.div`
  
  cursor: pointer;
  border: 1px solid #f1f1f1;
  height: 2rem;
  width: 2rem;
  line-height: 1.3rem;
  border-radius: 20px;
  text-align: center;
  
  
  margin-top: 12px;
  font-size:1.5rem;
  white-space: nowrap;
  position: absolute;
  right: 15px;
 
  &:hover{
    background: #fff;

  }


`

const ModalCategoriesWrapper = styled.div`

  position: fixed;
  top: 62px;
  background: white;
  height: calc(100vh - 62px);
  width: 100vw;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;

`

const More = ({toggleMore}) => {

    return <MoreWraper onClick={() => toggleMore(true)}>...</MoreWraper>

}


const summarize = ([first, second, ...rest], category_uuid) => {


  let summ = []
  const current = rest.find(c => c.category_uuid === category_uuid)
  if (current && current.order > 2) {
    summ = [first, current]
  }else {
    summ = [first, second]
  }

  return [{label: 'All', category_uuid:''}, ...summ].map((c, i) => 
  <Category  key={i} {...{c, active: category_uuid === c.category_uuid}} />)
}

export const CategoriesMenu = ({categories, location}) => {

  const ref = useRef()

  categories.sort((a, b) => a.order - b.order)

  const [sticky, setSticky] = useState(false)
  const [moreOn, toggleMore] = useState(false)

  const category_uuid = getCategoryUuidParam(location)

  const handleScroll = () => {
    const r = ref.current.getBoundingClientRect().top<=0
  
    setSticky(r)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])


  const activeCategory = categories.find((c,) => category_uuid === c.category_uuid)
  const activeCategoryLabel = activeCategory ? activeCategory.label : 'All'
  
  const cats = [{label: 'All', category_uuid:''}, ...categories].map((c, i) => 
  <Category  key={i} {...{c, active: category_uuid === c.category_uuid}} />)

  const catslist = isMobile() 
  //? <>{summarize(categories,category_uuid)}<More {...{moreOn, toggleMore}} /></> 
  ? cats
  : cats


  return <CategoriesWrapper ref={ref}>
      <Seo title={`${activeCategoryLabel} - NAICE`} description={`${activeCategoryLabel} projects - NAICE`} />

      <div className={`panel ${sticky?"sticky": ""}`}>
        {catslist}        
      </div>
    {moreOn ? <Modal><ModalCategoriesWrapper onClick={() => toggleMore(false)}>{cats}</ModalCategoriesWrapper></Modal> : null}
  </CategoriesWrapper>
  

}